<script setup>
let entering = ref(false)
let leaving = ref(false)
let loaderVisible = ref(false)

let router = useRouter()
let pageTransitionStore = usePageTransitionStore()
let menu = useMenuStore()

const hideMenuTimeout = useTimeoutFn(
  () => {
    menu.active = false
  },
  250,
  {
    immediate: false,
  },
)

const loaderTimeout = useTimeoutFn(
  () => {
    loaderVisible.value = true
  },
  1250,
  {
    immediate: false,
  },
)

let pageTransitionRef = ref()

let nextCallback = ref()

router.beforeEach((to, from, next) => {
  if (import.meta.env.SSR) return next()
  if (from.name === null) return next()
  if (!pageTransitionStore.isEnabled) {
    menu.active = false
    next()
    return
  }

  nextCallback.value = next

  hideMenuTimeout.start()
  loaderTimeout.start()

  entering.value = true

  useEventListener(
    pageTransitionRef,
    'transitionend',
    () => {
      next()
    },
    {
      once: true,
    },
  )
})

let nuxtApp = useNuxtApp()

router.afterEach(async (to, from) => {
  if (from.href === to.href) return
  if (import.meta.env.SSR) return
  if (!pageTransitionStore.isEnabled) return
  if (from.name === null) return

  // Wait until the page is mounted
  await new Promise(resolve => {
    let unlisten = nuxtApp.hook('page:finish', () => {
      unlisten()
      resolve()
    })
  })

  // Wait until the progress store is ready
  await until(() => pageTransitionStore.canContinue).toBe(true)

  entering.value = false
  leaving.value = true
  loaderVisible.value = false

  hideMenuTimeout.stop()
  loaderTimeout.stop()

  useEventListener(
    pageTransitionRef,
    'transitionend',
    () => {
      leaving.value = false
    },
    {
      once: true,
    },
  )
})
</script>

<template>
  <div class="page-transition" :class="{ entering, leaving }" ref="pageTransitionRef">
    <div class="loading-indicator" :class="{ visible: loaderVisible }">
      <slot>Einen Moment Geduld bitte.</slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page-transition {
  --initial-translate: translateX(calc(var(--triangle-height-fraction) * -75vh));
  --swiper-in-translate: translateX(calc(100vw + var(--triangle-height-fraction) * 150vh));
  --swiper-out-translate: translateX(calc(2 * (100vw + var(--triangle-height-fraction) * 150vh)));
  --skew: skew(calc(-1 * var(--skew-angle)));

  position: fixed;
  top: 0;
  bottom: 0;
  right: 100%;
  background-color: var(--primary-color);
  z-index: 99999;
  height: var(--100dvh);
  width: calc(100vw + var(--triangle-height-fraction) * 150vh);
  text-align: center;
  font-size: clamp(24px, 0.75rem + 6vw, 40px);
  color: #fff;
  display: flex;
  transition: transform ease 650ms;
  transform: var(--initial-translate) var(--skew);
  opacity: 0;
  pointer-events: none;

  &.entering {
    opacity: 1;
    transform: var(--initial-translate) var(--swiper-in-translate) var(--skew);
  }

  &.leaving {
    opacity: 1;
    transform: var(--initial-translate) var(--swiper-out-translate) var(--skew);
  }
}

@keyframes loading-indicator {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) skew(var(--skew-angle));
  z-index: 20001;
  color: #000;
  opacity: 0;
  transition: opacity ease 500ms;

  &.visible {
    animation: loading-indicator ease 1s infinite alternate;
    opacity: 1;
  }
}
</style>
