export let useProgressStore = defineStore('progress', () => {
  let trackedProgresses = ref(new Set())
  let isComplete = computed(() =>
    [...trackedProgresses.value].every(progress => progress.isFinished),
  )

  return {
    isComplete,
    track(progress) {
      trackedProgresses.value.add(progress)

      progress.onDispose(() => {
        trackedProgresses.value.delete(progress)
      })
    },
  }
})
