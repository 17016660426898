export let usePageTransitionStore = defineStore('pageTransition', () => {
  let enabled = ref(true)
  let router = useRouter()
  let progressStore = useProgressStore()
  let scrollPosition = ref({ left: 0, top: 0 })

  let unwatchRoute

  let canContinue = computed(() => progressStore.isComplete)

  return {
    isEnabled: computed(() => enabled.value),
    canContinue,

    saveScrollPosition({ left, top }) {
      scrollPosition.value = { left, top }
    },

    enable() {
      enabled.value = true
      unwatchRoute?.()
    },
    disable() {
      enabled.value = false
      unwatchRoute?.()
    },
    disableOnce() {
      enabled.value = false

      unwatchRoute = router.afterEach(() => {
        setTimeout(() => {
          enabled.value = true
        }, 0)
        unwatchRoute()
      })
    },
  }
})
