import { default as _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47404_46vueMeta } from "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/404.vue?macro=true";
import { default as _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47_91_91slug_93_93_46vueMeta } from "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/[[slug]].vue?macro=true";
import { default as _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47work_47_91slug_93_46vueMeta } from "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/work/[slug].vue?macro=true";
export default [
  {
    name: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47404_46vueMeta?.name ?? "404",
    path: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47404_46vueMeta?.path ?? "/404",
    file: "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/404.vue",
    children: [],
    meta: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47404_46vueMeta,
    alias: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47404_46vueMeta?.alias || [],
    redirect: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47404_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47_91_91slug_93_93_46vueMeta?.name ?? "slug",
    path: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47_91_91slug_93_93_46vueMeta?.path ?? "/:slug?",
    file: "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/[[slug]].vue",
    children: [],
    meta: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47_91_91slug_93_93_46vueMeta,
    alias: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47_91_91slug_93_93_46vueMeta?.alias || [],
    redirect: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47_91_91slug_93_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47work_47_91slug_93_46vueMeta?.name ?? "work-slug",
    path: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47work_47_91slug_93_46vueMeta?.path ?? "/work/:slug",
    file: "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/work/[slug].vue",
    children: [],
    meta: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47work_47_91slug_93_46vueMeta,
    alias: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47work_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47home_47runner_47work_47moduleplus_45moduleplus_46ch_47moduleplus_45moduleplus_46ch_47nuxt_45app_47pages_47work_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/pages/work/[slug].vue").then(m => m.default || m)
  }
]