<script setup>
let designStore = useDesignStore()

let { width: windowWidth, height: windowHeight } = useWindowSize()

let body = useDom(() => document.body)

// If a dynamically imported module cannot be found, the reason is usually that
// a new deployment took place and the browser cannot loead a no longer existing module.
// In this case, we reload the page to get the new version.
useNuxtApp().vueApp.config.errorHandler = error => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location.reload()
  }
}

designStore.applyAsCssVars()

let windowHeightVar = useCssVar('--window-height', body)
syncRefs(windowHeight, windowHeightVar)

let triangleHeightFractionVar = useCssVar('--triangle-height-fraction', body)
syncRefs(() => designStore.derived.triangleHeightFraction, triangleHeightFractionVar)

let scrollbarWidth = computed(() => `${windowWidth.value - (body.value?.clientWidth ?? 0)}px`)
let scrollbarWidthVar = useCssVar('--scrollbar-width', body)
syncRefs(scrollbarWidth, scrollbarWidthVar)

useHead({
  link: [{ rel: 'icon', type: 'image/svg+xml', href: '/favicon.svg' }],
})

useInitialRenderStore()
</script>

<template>
  <div class="app">
    <PageTransition />

    <NuxtPage />
  </div>
</template>

<style lang="scss">
@import '@/assets/css/globals';

body {
  // A corrected value for 1vw which removes space-eating scrollbar's width
  --vw: calc((100vw - var(--scrollbar-width, 0px)) / 100);
  --100vw: calc(100 * var(--vw));
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 400ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
