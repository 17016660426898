import type { RouterOptions } from '@nuxt/schema'
import { delay } from '@/assets/js/util'

export default <RouterOptions>{
  // Make Vue Router scroll top when changing routes
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }

    return { top: 0 }
  }
}
