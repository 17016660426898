export default defineNuxtPlugin(nuxtApp => {
  /**
   * @type {IntersectionObserver}
   */
  let observer

  let delayingElements = new Map()

  nuxtApp.vueApp.directive('enter', {
    mounted(element, binding, vnode) {
      element.dataset.enter = ''
      element.dataset.enterGroup = binding.value ?? 'default'

      if (!observer) {
        // Create an observer to notice when elements become visible
        observer = new IntersectionObserver(entries => {
          for (let entry of entries) {
            if (entry.isIntersecting) {
              // Only need to observe elements until in viewport once -> unobserve now
              observer.unobserve(entry.target)

              let endHandler = event => {
                if (event.target === entry.target) {
                  delayingElements.get(entry.target.dataset.enterGroup).delete(entry.target)
                  entry.target.removeEventListener('transitionend', endHandler)

                  delete entry.target.dataset.enter
                  delete entry.target.dataset.enterGroup
                  entry.target.classList.remove('enter-animate')
                  entry.target.style.removeProperty('--enter-offset')
                  entry.target.style.removeProperty('--enter-delay')
                }
              }
              entry.target.addEventListener('transitionend', endHandler)

              if (!('enterConcurrent' in entry.target.dataset)) {
                if (!delayingElements.has(entry.target.dataset.enterGroup)) {
                  delayingElements.set(entry.target.dataset.enterGroup, new Set())
                }

                delayingElements.get(entry.target.dataset.enterGroup).add(entry.target)
              }

              entry.target.style.setProperty(
                '--enter-offset',
                String(delayingElements.get(entry.target.dataset.enterGroup).size),
              )

              entry.target.classList.add('enter-animate')
            }
          }
        })
      }

      observer.observe(element)
    },
    unmounted(element) {
      observer?.unobserve?.(element)
    },
    getSSRProps: () => ({
      'data-enter': '',
    }),
  })
})
