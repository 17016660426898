import { checkWebpSupport } from '@/assets/js/util'

export default defineNuxtPlugin(async nuxtApp => {
  if (nuxtApp.ssrContext) return
  if (!(await checkWebpSupport())) {
    document.documentElement.classList.add('no-webp')
  } else {
    document.documentElement.classList.add('supports-webp')
  }
})
