export let useInitialRenderStore = defineStore('initial-render', () => {
  let initialRender = ref(true)
  let router = useRouter()

  let unwatch = router.beforeEach(() => {
    unwatch()
    initialRender.value = false
  })

  return {
    isInitial: initialRender,
  }
})
