import { kebabCase, omit } from 'lodash-es'

export let useDesignStore = defineStore('design', () => {
  let primaryColor = '#ffcd00'
  let black = '#000'
  let white = '#fff'
  let scrollbarColor = '#fff'
  let tokens = ref({
    // Colors
    background: black,
    foreground: white,
    black,
    white,
    primaryColor,
    scrollbarColor,
    selectionBackground: primaryColor + 'dd',
    selectionForeground: black,

    // Global layout
    skewDegrees: 19,

    // Typography

    // Adjustment to be made to font size [x] to reach exactly [x] pixels in text height
    fontSizeAdjust: 1.4125,

    // Vertical offset to use to reach exactly [x] pixels in text height
    fontSizeOffset: -0.1,
  })

  let scopedTokens = ref({})

  function setObject(data) {
    tokens.value = { ...tokens.value, ...data }
  }

  function set(arg1, arg2) {
    if (typeof arg1 === 'object') {
      setObject(arg1)
    } else {
      setObject({ [arg1]: arg2 })
    }
  }

  function setScopedObject(data) {
    scopedTokens.value = { ...scopedTokens.value, ...data }

    tryOnScopeDispose(() => {
      scopedTokens.value = omit(scopedTokens.value, Object.keys(data))
    })
  }

  function setScoped(arg1, arg2) {
    if (typeof arg1 === 'object') {
      setScopedObject(arg1)
    } else {
      setScopedObject({ [arg1]: arg2 })
    }
  }

  let mixedTokens = computed(() => ({ ...tokens.value, ...scopedTokens.value }))

  let toPropertyName = string => `--${kebabCase(string)}`

  let cssVarsEntries = computed(() =>
    Object.entries(mixedTokens.value).map(([name, value]) => [toPropertyName(name), String(value)]),
  )
  let cssVars = computed(() => Object.fromEntries(cssVarsEntries.value))

  function applyClientCssVars() {
    for (let token of Object.keys(mixedTokens.value)) {
      let property = toPropertyName(token)
      let value = computed(() => cssVars.value[property])
      let cssVar = useCssVar(property, document.body)
      syncRefs(value, cssVar)
    }
  }

  let mounted = useMounted()
  function applyAsCssVars({ live = true } = {}) {
    if (import.meta.env.SSR) {
      useHead({
        style: [
          {
            type: 'text/css',
            children: `body { ${cssVarsEntries.value
              .map(([property, value]) => `${property}: ${value};`)
              .join('\n')}; }`,
          },
        ],
      })
    } else if (live) {
      // Wait until mounted to avoid flickering
      until(mounted)
        .toBe(true)
        .then(() => {
          applyClientCssVars()
        })
    }
  }

  return {
    tokens: mixedTokens,
    derived: {
      triangleHeightFraction: computed(() =>
        Math.tan((mixedTokens.value.skewDegrees * Math.PI) / 180),
      ),
    },
    set,
    setScoped,
    applyAsCssVars,
  }
})
