import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq from "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import plugins_animate_on_scroll_js_9XcGkvIvKH from "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/plugins/animate-on-scroll.js";
import plugins_webp_support_js_EosWVqgwtv from "/home/runner/work/moduleplus-moduleplus.ch/moduleplus-moduleplus.ch/nuxt-app/plugins/webp-support.js";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq,
  plugins_animate_on_scroll_js_9XcGkvIvKH,
  plugins_webp_support_js_EosWVqgwtv
]